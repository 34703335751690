import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'

import { backgroundColors, colors } from 'styles/colors'
import { useTranslation } from 'react-i18next'
import { LocalizedLinkAnchor } from 'i18n/LocalizedLink'
import { getDataByLanguage } from 'utils/getDataByLanguage'
import { sendEvent } from 'tracking'
import { Icon } from 'components/Icon'

const Wrapper = styled.div`
    background-color: ${backgroundColors.index};
    width: 100%;
    padding: 0 15px;
    box-sizing: border-box;
`

const OurServicesWrapper = styled.div`
    width: 100%;
    padding: 1px 16px;
    background-color: ${backgroundColors.index};
    box-sizing: border-box;
    border-left: 1px solid ${colors.dark};
    border-right: 1px solid ${colors.dark};
`
const LocalizedLinkAnchorBlock = styled(LocalizedLinkAnchor)`
    width: 100%;
    display: flex;
    align-items: center;
    text-decoration: none;
    margin: 16px 0;
    padding: 6px 16px;
    border: 1px solid black;
    border-radius: 16px;
    box-sizing: border-box;
    z-index: 1;
    :focus {
        background-color: white;
        border: 2px solid black;
    }
`
const Text = styled.p`
    font-size: 22px;
    margin-left: 10px;
    color: black;
    font-weight: 100;
`
const IconStyled = styled(Icon)`
    width: 57px;
    height: 57px;
    flex-shrink: 0;
    color: black;
`
export const OurServicesMobile = () => {
    const { i18n, t } = useTranslation()
    const data = useStaticQuery(graphql`
        query {
            allOurServicesMobileYaml {
                edges {
                    node {
                        iconFaq
                        faqTitle
                        iconStyle
                        styleTitle
                        iconPromo
                        promoTitle
                        icon3d
                        title3d
                        iconViza
                        vizaTitle
                        iconDraw
                        drawTitle
                        measureTitle
                        iconMeasure
                        parent {
                            ... on File {
                                name
                            }
                        }
                    }
                }
            }
        }
    `)

    const allOurServicesData = getDataByLanguage(
        data.allOurServicesMobileYaml,
        i18n.language
    )

    return (
        <Wrapper>
            <OurServicesWrapper>
                <LocalizedLinkAnchorBlock
                    to={'/exapmle'}
                    onClick={() => {
                        sendEvent('Click', {
                            eventCategory: 'ShowExampleOfProject',
                            placement: 'Our Services',
                            target: 'Example',
                        })
                    }}
                >
                    <IconStyled iconName={allOurServicesData.iconPromo} />
                    <Text>{allOurServicesData.promoTitle}</Text>
                </LocalizedLinkAnchorBlock>
                <LocalizedLinkAnchorBlock
                    to={'/style'}
                    onClick={() => {
                        sendEvent('Click', {
                            eventCategory: 'ShowMoreStyleIndex',
                            placement: 'Our Services',
                            target: 'Style',
                        })
                    }}
                >
                    <IconStyled iconName={allOurServicesData.iconStyle} />
                    <Text> {allOurServicesData.styleTitle}</Text>
                </LocalizedLinkAnchorBlock>
                <LocalizedLinkAnchorBlock
                    to={'/exapmleArchitect'}
                    onClick={() => {
                        sendEvent('Click', {
                            eventCategory: 'ShowExampleOfProjectArchitect',
                            placement: 'Our Services',
                            target: 'Example Architect',
                        })
                    }}
                >
                    <IconStyled iconName={'architect-icon.svg'} />
                    <Text>{t('architectTitle')}</Text>
                </LocalizedLinkAnchorBlock>
                <LocalizedLinkAnchorBlock
                    to={'/example3d'}
                    onClick={() => {
                        sendEvent('Click', {
                            eventCategory: 'ShowExampleOfProject3d',
                            placement: 'Our Services',
                            target: 'Example3d',
                        })
                    }}
                >
                    <IconStyled iconName={allOurServicesData.icon3d} />
                    <Text>{allOurServicesData.title3d}</Text>
                </LocalizedLinkAnchorBlock>
                <LocalizedLinkAnchorBlock
                    to={'/exampleViza'}
                    onClick={() => {
                        sendEvent('Click', {
                            eventCategory: 'ShowExampleOfViza',
                            placement: 'Our Services',
                            target: 'Viza',
                        })
                    }}
                >
                    <IconStyled iconName={allOurServicesData.iconViza} />
                    <Text>{allOurServicesData.vizaTitle}</Text>
                </LocalizedLinkAnchorBlock>
                <LocalizedLinkAnchorBlock
                    to={'/editor'}
                    onClick={() => {
                        sendEvent('Click', {
                            eventCategory: 'ShowMoreCADIndex',
                            placement: 'Our Services',
                            target: 'CAD',
                        })
                    }}
                >
                    <IconStyled iconName={allOurServicesData.iconDraw} />
                    <Text>{allOurServicesData.drawTitle}</Text>
                </LocalizedLinkAnchorBlock>
                <LocalizedLinkAnchorBlock
                    to={'/measure'}
                    onClick={() => {
                        sendEvent('Click', {
                            eventCategory: 'ShowMoreMeasureIndex',
                            placement: 'Our Services',
                            target: 'measure',
                        })
                    }}
                >
                    <IconStyled iconName={allOurServicesData.iconMeasure} />
                    <Text> {allOurServicesData.measureTitle}</Text>
                </LocalizedLinkAnchorBlock>
                <LocalizedLinkAnchorBlock
                    to={'/faq'}
                    onClick={() => {
                        sendEvent('Click', {
                            eventCategory: 'ShowMoreFAQIndex',
                            placement: 'Our Services',
                            target: 'FAQ',
                        })
                    }}
                >
                    <IconStyled iconName={allOurServicesData.iconFaq} />
                    <Text> {allOurServicesData.faqTitle}</Text>
                </LocalizedLinkAnchorBlock>
            </OurServicesWrapper>
        </Wrapper>
    )
}
