import React from 'react'

import { OurServices } from 'blocks/OurServices'
import { OurServicesMobile } from 'blocks/OurServicesMobile'
import { HomeHero } from 'blocks/Heros/HomeHero'
import { HelmetFunc } from 'components/PageMetaData'
import { Layout } from 'layout/Layout'
import { RunningLine } from 'components/RunningLine'
import { useTranslation } from 'react-i18next'
import { OurServicesBottom } from 'blocks/OurServicesBottom'
import { Reviews } from 'blocks/Reviews'
import styled from 'styled-components'
import { displayWidth } from 'styles/width'
import { OurServices3 } from 'blocks/OurServices3'

const pageMetadata = {
    uk: {
        title:
            "Дизайн інтер'єру та архітектурне проектування житлових та нежитлових приміщень",
        description:
            'ClearLine розробляє архітектурні проекти та дизайн-проекти квартир, будинків, офісів та комерційних приміщень - Акція з низькими цінами',
    },
    ru: {
        title:
            'Дизайн интерьера и архитектурное проектирование жилых и нежилых помещений',
        description:
            'ClearLine разрабатывает архитектурные проекты и дизайн-проекты квартир, домов, офисов и коммерческих помещений - Акция с низкими ценами',
    },
    en: {
        title:
            'Residential and Non-Residential Interior Design & Architectural Design',
        description:
            'ClearLine develops architectural projects and design projects for apartments, houses, offices and commercial premises - Promotion with low prices',
    },
}

const DesktopWrapper = styled.div`
    display: none;
    @media (min-width: ${displayWidth.tablet}) {
        display: block;
    }
`
const MobileWrapper = styled.div`
    display: block;
    @media (min-width: ${displayWidth.tablet}) {
        display: none;
    }
`

const IndexPage = () => {
    const { t } = useTranslation()
    return (
        <Layout>
            <HelmetFunc data={pageMetadata} />
            <HomeHero />
            <DesktopWrapper>
                <RunningLine>{t('designProject99')}</RunningLine>
                <OurServices />
                <OurServicesBottom />
                <OurServices3 />
                <RunningLine inverse>{t('designProject99')}</RunningLine>
                <Reviews />
            </DesktopWrapper>
            <MobileWrapper>
                <OurServicesMobile />
            </MobileWrapper>
        </Layout>
    )
}

export default IndexPage
