import React from 'react'
import styled from 'styled-components'

import { displayWidth } from 'styles/width'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { SlickNext, SlickPrevious } from 'components/SlickNavigation'

const CarouselWrapper = styled.div`
    width: 100vw;
    box-sizing: border-box;
    overflow: hidden;
    margin: 0;
    height: auto;
    padding: 0 16px;
    @media (min-width: ${displayWidth.tablet}) {
        max-width: calc((100vw - 160px) * 0.6666);
        height: auto;
        padding: 0;
    }
    @media (min-width: ${displayWidth.desktop}) {
        max-width: calc((${displayWidth.desktop} - 160px) * 0.6666);
    }
    .slick-list {
        margin: 0 0 -4px -0;
        @media (min-width: ${displayWidth.tablet}) {
            margin: 0 -30px;
        }
    }
    img {
        cursor: grab;
    }
    .slick-slide > div {
        @media (min-width: ${displayWidth.tablet}) {
            margin: -4px 30px;
        }
    }
    .slick-dots {
        bottom: -22px;
        left: 0;

        @media (min-width: ${displayWidth.tablet}) {
            bottom: 5px;
        }
        li {
            width: 10px;
            button {
                width: 10px;
                ::before {
                    width: 10px;
                }
            }
        }
    }
`

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const SliderComponent = ({ children, forwardRef, ...props }: any) => {
    const settings = {
        nextArrow: <SlickNext mobile />,
        prevArrow: <SlickPrevious mobile />,
    }
    return (
        <CarouselWrapper {...props}>
            <Slider {...settings} {...props} ref={forwardRef}>
                {children}
            </Slider>
        </CarouselWrapper>
    )
}
