import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'

import { mobileAfterBorder } from 'styles/mobileAfterBorder'
import { colors, backgroundColors } from 'styles/colors'
import { displayWidth } from 'styles/width'
import { Container } from 'components/Container'
import { Button } from 'components/Button'
import { LocalizedLinkAnchor } from 'i18n/LocalizedLink'
import { sendEvent } from 'tracking'
import { indent } from 'styles/indent'

import { getDataByLanguage } from '../utils/getDataByLanguage'

const FormWrapper = styled.div`
    display: flex;
    justify-content: center;
    position: relative;
    background-color: ${backgroundColors.formPromo};
    width: 100%;
    ${mobileAfterBorder};
    @media (min-width: ${displayWidth.tablet}) {
        border-bottom: 1px solid ${colors.dark};
    }
`

const Block = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 16px 32px;
    box-sizing: border-box;
    position: relative;
    border-bottom: 1px solid ${colors.dark};
    z-index: 1;
    @media (min-width: ${displayWidth.tablet}) {
        justify-content: space-between;
        border-bottom: none;
        padding: 16px;
    }
    @media (min-width: ${displayWidth.desktop}) {
        padding: 56px 32px;
        align-items: flex-start;
    }
    :after {
        position: absolute;
        top: 0;
        bottom: 0;
        left: ${indent.mobile};
        right: ${indent.mobile};
        border-left: 1px solid ${colors.dark};
        border-right: 1px solid ${colors.dark};
        content: '';
        z-index: -1;
        @media (min-width: ${displayWidth.tablet}) {
            border-left: none;
            border-right: none;
        }
    }
`
const PromoBlock = styled(Block)`
    background-color: ${backgroundColors.promotion};
    border-top: 1px solid #000;
    flex: 0 0;
    @media (min-width: ${displayWidth.tablet}) {
        border-top: none;
        justify-content: space-between;
        border-bottom: none;
        padding: 32px 16px 0px;
        border-right: 1px solid black;
    }
    @media (min-width: ${displayWidth.desktop}) {
        padding: 32px 32px 0px;
        align-items: flex-start;
    }
    :after {
        display: none;
    }
`

const FaqBlock = styled(Block)`
    background-color: ${backgroundColors.index};
    flex: 0 0;
    @media (min-width: ${displayWidth.tablet}) {
        justify-content: space-between;
        border-bottom: none;
        padding: 32px 16px 0px;
        border-right: 1px solid black;
    }
    @media (min-width: ${displayWidth.desktop}) {
        padding: 32px 32px 0px;
        align-items: flex-start;
    }
    :after {
        display: none;
    }
`
const ExampleBlock3D = styled(Block)`
    background-color: ${backgroundColors.project};
    flex: 0 0;
    @media (min-width: ${displayWidth.tablet}) {
        justify-content: space-between;
        border-bottom: none;
        padding: 32px 16px 0px;
        border-right: 1px solid black;
    }
    @media (min-width: ${displayWidth.desktop}) {
        padding: 32px 32px 0px;
        align-items: flex-start;
    }
    :after {
        display: none;
    }
`

const Title = styled.h2`
    font-family: 'Yeseva One', sans-serif;
    font-style: normal;
    font-size: 28px;
    line-height: 32px;
    letter-spacing: 1px;
    margin-top: 24px;
    text-align: center;
    color: ${colors.darkText};
    @media (min-width: ${displayWidth.desktop}) {
        font-size: 33px;
        line-height: 39px;
        letter-spacing: 1.68px;
        text-align: left;
    }
`
const SubTitle = styled.h3`
    font-weight: normal;
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    letter-spacing: 0.4px;
    color: ${colors.dark};
    margin-top: 10px;
    strong {
        font-weight: bold;
    }
    a {
        color: ${colors.dark};
    }
    p,
    li {
        text-align: center;
    }
    ol,
    ul {
        margin-left: 1em;
    }
    ol {
        list-style: decimal;
    }
    @media (min-width: ${displayWidth.desktop}) {
        text-align: left;
        ul,
        p,
        li {
            text-align: left;
            margin-left: 0;
        }
    }
`

const ButtonStyled = styled(Button)`
    width: 264px;
    margin: 50px auto;
    z-index: 3;
    background-color: transparent;
    color: ${colors.darkText};
    border-width: 4px;
    :hover {
        background-color: #000;
        color: #fff;
    }
    @media (max-width: 330px) {
        width: 250px;
    }
    @media (min-width: ${displayWidth.tablet}) {
        width: 220px;
    }
    @media (min-width: ${displayWidth.desktop}) {
        width: 264px;
    }
`
const TitleBlock = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media (min-width: ${displayWidth.desktop}) {
    }
`

export const OurServices3 = () => {
    const { i18n, t } = useTranslation()
    const data = useStaticQuery(graphql`
        query {
            allCopirightYaml {
                edges {
                    node {
                        text
                        parent {
                            ... on File {
                                name
                            }
                        }
                    }
                }
            }
            allOurServicesYaml {
                edges {
                    node {
                        titlePromo
                        sample
                        pricePromo
                        subTitlePromo
                        buttonTextPromo
                        titleCad
                        subTitleCad
                        buttonTextCad
                        titleFaq
                        subTitleFaq
                        buttonTextFaq
                        measureTitle
                        measureSubTitle
                        parent {
                            ... on File {
                                name
                            }
                        }
                    }
                }
            }
        }
    `)

    const allOurServicesData = getDataByLanguage(
        data.allOurServicesYaml,
        i18n.language
    )
    const { text } = getDataByLanguage(data.allCopirightYaml, i18n.language)
    return (
        <FormWrapper>
            <Container columns={'1fr'} tabletColumns={'1fr 1fr 1fr'}>
                <ExampleBlock3D>
                    <TitleBlock>
                        <SubTitle>{text}</SubTitle>
                        <SubTitle>{t('copiright')}</SubTitle>
                    </TitleBlock>
                </ExampleBlock3D>
                <PromoBlock>
                    <Title>{allOurServicesData.measureTitle}</Title>
                    <SubTitle>{allOurServicesData.measureSubTitle}</SubTitle>
                    <LocalizedLinkAnchor
                        to={'/measure'}
                        onClick={() => {
                            sendEvent('Click', {
                                eventCategory: 'ShowMoreMeasureIndex',
                                placement: 'Our Services',
                                target: 'Measure',
                            })
                        }}
                    >
                        <ButtonStyled aria-label={(t('goTo'), t('Measure'))}>
                            {allOurServicesData.buttonTextFaq}
                        </ButtonStyled>
                    </LocalizedLinkAnchor>
                </PromoBlock>
                <FaqBlock>
                    <Title>{allOurServicesData.titleFaq}</Title>
                    <SubTitle>{allOurServicesData.subTitleFaq}</SubTitle>
                    <LocalizedLinkAnchor
                        to={'/faq'}
                        onClick={() => {
                            sendEvent('Click', {
                                eventCategory: 'ShowMoreFAQIndex',
                                placement: 'Our Services',
                                target: 'FAQ',
                            })
                        }}
                    >
                        <ButtonStyled aria-label={(t('goTo'), t('promo'))}>
                            {allOurServicesData.buttonTextFaq}
                        </ButtonStyled>
                    </LocalizedLinkAnchor>
                </FaqBlock>
            </Container>
        </FormWrapper>
    )
}
